import request from '../utils/request.js'

// 首页轮播
export function getlunbos(query={}) {
  return request({
    url: 'api/lunbos',
    method: 'GET',
    params: query
  })
}
// 联系我们-平台介绍
export function getwes(query={}) {
  return request({
    url: 'api/wes',
    method: 'GET',
    params: query
  })
}
// 分类
export function getplates(query={}) {
  return request({
    url: 'api/plates',
    method: 'GET',
    params: query
  })
}
// 列表
export function getplatexts(query={}) {
  return request({
    url: 'api/platexts',
    method: 'GET',
    params: query
  })
}
// 内容
export function getdetails(query={}) {
  return request({
    url: 'api/platexts/'+query.id,
    method: 'GET',
    params: query
  })
}
// 时间轴
export function getaxis(query={}) {
  return request({
    url: 'api/axis',
    method: 'GET',
    params: query
  })
}
//广告
export function getplateAds(query={}) {
  return request({
    url: 'api/plateAds',
    method: 'GET',
    params: query
  })
}
//国家列表
export function getcountrys(query={}) {
  return request({
    url: 'api/countrys',
    method: 'GET',
    params: query
  })
}
//城市列表
export function getcitys(query={}) {
  return request({
    url: 'api/citys',
    method: 'GET',
    params: query
  })
}



// 留言 
export function mess(query={}) {
  return request({
    url: 'api/mess',
    method: 'POST',
    params: query
  })
}

// 模块表单提交  
export function submit(query={}) {
  return request({
    url: 'api/platext/store',
    method: 'POST',
    params: query
  })
}

//文件上传  
export function platextApplys(query) {
  return request({
    url: 'api/platextApplys',
    method: 'POST',
    params: query
  })
}

// 登录
export function login(query={}) {
  return request({
    url: 'api/user/login',
    method: 'POST',
    params: query
  })
}

// 邮箱验证码
export function email_send(query={}) {
  return request({
    url: 'api/email_send',
    method: 'GET',
    params: query
  })
}

// 邮箱注册
export function emailRegister(query={}) {
  return request({
    url: 'api/emailRegister',
    method: 'POST',
    params: query
  })
}
// 手机验证码
export function mobile_send(query={}) {
  return request({
    url: 'api/mobile_send',
    method: 'GET',
    params: query
  })
}

// 手机注册
export function mobileRegister(query={}) {
  return request({
    url: 'api/mobileRegister',
    method: 'POST',
    params: query
  })
}

// 找回密码
export function forgot(query={}) {
  return request({
    url: 'api/user/forgot',
    method: 'POST',
    params: query
  })
}


// logo
export function getzhans(query={}) {
  return request({
    url: 'api/zhans/'+query.id,
    method: 'GET',
    params: query
  })
}