import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '../store/index'
import {mapState} from 'vuex'    

import CONFIG from "./config.js"

// create an axios instance
const service = axios.create({
  // baseURL: '/api',
  baseURL: CONFIG.baseUrl,
  headers: {'Accept': 'application/json' },
  
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
	  // console.log(config);
	  let token = window.localStorage.getItem('token')
    let is_pay = window.localStorage.getItem('is_pay')
    if (token) {
      config.params['api_token'] = token
    }
    if(is_pay){
      config.params['is_pay'] = is_pay
    }
    // let zh_id = store.state.zh_id
    let zh_id = window.localStorage.getItem('zh_id')==null?store.state.zh_id:window.localStorage.getItem('zh_id')
    // console.log(zh_id)
    config.params['zh_id'] = zh_id
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    return res
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service